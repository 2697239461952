<template>
  <base-section id="learn-more" class="bac-section">
    <!-- <base-icon class="mt-8 mb-6" color="#03045E">
      mdi-file-star
    </base-icon> -->
    <base-section-heading
      title="Learn More About What's Happening"
      class="mt-8"
    >
      Here is a collection of public documents we've collected so far:
    </base-section-heading>
    <v-responsive class="mx-auto" max-width="1200">
      <v-container fluid>
        <v-row>
          <v-col
            v-for="(card, i) in cards"
            :key="i"
            cols="12"
            sm="6"
            md="6"
            class="px-4"
          >
            <v-card color="#0077B6" dark>
              <v-card-title
                class="text-h5 pb-0 card-title"
                v-html="card.title"
              ></v-card-title>
              <div
                v-if="card.thumb"
                @click="card.file ? download(card.file) : download(card.link)"
                :class="card.horizontal ? 'thumb-horizontal' : 'thumb-vertical'"
              >
                <v-img :src="`thumb/${card.thumb}`"></v-img>
              </div>
              <v-card-text style="letter-spacing: 0.25px;">
                <div v-html="card.text"></div>
              </v-card-text>
              <div style="clear: both;"></div>
              <v-card-actions>
                <v-btn
                  v-if="card.file"
                  color="#03045E"
                  class="px-4"
                  block
                  @click="download(card.file)"
                >
                  <v-icon class="mr-2">mdi-download-circle</v-icon>
                  Download
                </v-btn>
                <v-btn
                  v-if="card.video"
                  color="#03045E"
                  class="px-4"
                  block
                  @click="download(card.link)"
                >
                  <v-icon class="mr-2">mdi-video</v-icon>
                  View
                </v-btn>
                <v-btn
                  v-if="card.link && !card.video"
                  color="#03045E"
                  class="px-4"
                  block
                  @click="download(card.link)"
                >
                  <v-icon class="mr-2">mdi-dock-window</v-icon>
                  Open
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-responsive>
  </base-section>
</template>

<script>
import learnMoreJson from "@/learn-more.json";
export default {
  name: "LearnMore",

  data: () => ({
    cards: learnMoreJson
  }),
  methods: {
    download(link) {
      this.$hub.$emit("openPdf", link, "learn-more");
    },
  }
};
</script>

<style lang="scss">
#learn-more {
  background-color: #caf0f8;
  padding: 2em 1em !important;
  img {
    width: 100%;
    max-width: 1000px;
    margin: 1em auto;
  }
  .container {
    max-width: 1000px;
  }
  .thumb-horizontal {
    cursor: pointer;
    display: block;
    width: calc(100% - 60px);
    margin: 0.5em 30px 0;
  }
  .thumb-vertical {
    cursor: pointer;
    float: right;
    display: block;
    width: 40%;
    margin: 1em 1em 0.5em 0.5em;
  }
  .card-title {
    letter-spacing: 0.25px !important;
    word-break: normal !important;
  }
}
</style>
